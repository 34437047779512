import table from '@/mixin/table'
import CInputDateRange from '@/components/CInputDateRange'
import moment from "moment/moment";

export default {
    name: 'CompaniesList',
    components: {CInputDateRange},
    inject: ['mountedComponent'],
    mixins: [table],
    created() {
        this.search = this.$route.query.search || ''
    },
    data() {
        return {
            activity: [],
            fields: [
                {key: 'date', label: 'Date', _style: ''},
                {key: 'time', label: 'Time', _style: ''},
                {
                    key: 'project',
                    label: 'Project',
                    _style: '',
                },
                {key: 'type', label: 'Action', _style: ''},
                {
                    key: 'user',
                    label: 'User',
                    _style: '',
                },
                {
                    key: 'company',
                    label: 'Company',
                    _style: '',
                },
                {key: 'description', label: 'Description', _style: ''},
            ],
            tableFilterSearch: '',
        }
    },
    computed: {
        params() {
            return {
                per_page: this.perPage,
                page: this.activePage,
                search: this.search,
                sort_field: this.sort && this.sort.column,
                sort_direction: this.sort && this.sort.state,
                project_id:
                    this.$route.params.id !== 'all' ? this.$route.params.id : null,
            }
        },
    },
    methods: {
        formatTime(time) {
            return moment.utc(time, "h:mm A").local().format('HH:mm')
        },
        exportData() {
            const url = `${
                process.env.VUE_APP_BASE_API_URL
            }${this.$http.projects.exportActivity()}?${Object.keys(this.params)
                .map((key) => {
                    return `${key}=${this.params[key]}`
                })
                .join('&')}`
            window.open(url)
        },
        fetchData() {
            this.key++
            this.loadingTable = true
            this.$http.projects
                .fetchActivity({params: this.params})
                .then((res) => {
                    this.loadingTable = false
                    this.key++
                    this.activity = res.data.data.map((i) => ({
                        ...i,
                        project_id: i.project?.id,
                        company_id: i.company?.id,
                        user_id: i.user?.id,
                    }))
                    this.pages = res.data.meta.last_page
                    if (this.activePage > this.pages) {
                        this.activePage = this.activePage - 1
                    }
                })
                .catch(() => {
                    this.loadingTable = false
                })
        },
    },
}
